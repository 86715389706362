

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL9PostLab',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1: null,
        part2: null,
        part3: null,
        part4: null,
      },
      questions: [
        {
          text: 'a) When discussing how to determine if there was a correlation between shoe size and height, how did you decide which data you needed to gather?',
          inputName: 'part1',
        },
        {
          text: 'b) When discussing how to determine if there was a correlation between stride length and height, how did you decide which data you needed to gather?',
          inputName: 'part2',
        },
        {
          text: 'c) Once you get the data (height, stride length, and shoe length), how are you going to analyze it to test for a correlation? That is, how will you know if there IS a correlation or not?',
          inputName: 'part3',
        },
        {
          text: 'd) When you come to lab next week to perform your procedure and gather data for the analysis, what possible sources of error will you be looking to minimize? What kinds of things would make your measurements more accurate? Or less accurate?',
          inputName: 'part4',
        },
      ],
    };
  },
};
